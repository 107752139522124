.plDiv {
  padding-left: 20px;
  margin-top: 10px;
  width: fit-content;
  font-weight: bolder;
}

.plDiv:hover {
  cursor: pointer;
}

.plH2 {
  padding-left: 20px;
}

.plHr {
  margin: 10px 20px;
}


.createPlaylistBtn {
  height: 30px;
  justify-self: center;
  margin-left: 20px;
  color: white;
  height: 36px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  background-color: #ff5500;
  width: 130px;
}

.plHeader {
  display: flex;
  align-items: center;
}
