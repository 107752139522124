#delete-comment-button {
    border: none;
    cursor: pointer;
    appearance: none;
    background-color: inherit;
}


.chatcloud-and-number-of-comments {
    display:flex;
    align-items: center;
}

.container-for-song-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    margin-left: 58px;
    justify-content: center;
    align-items: flex-start;
    
}

.first-container-for-song-details {
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    width:100%;
    align-items:center;
 
  
}

.inner-song-details-page-likes-and-times-played {
    display:flex;
    flex-direction: row;
    justify-content:space-between;
    width: 100%;
}
.if-logged-in-features-on-song-details-page {
    display:flex;
    justify-content: space-around;
}

.song-info-on-song-details-page {
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}

.song-info {
    margin-left: 10px;
    margin-bottom: -20px;

}
.song-info.title {
    margin-top: 5px;
}

#individual-comment-in-the-list-song-details-page {
    padding-bottom: 18px;
}

#button-for-add-to-playlist-song-details-page {
    border: none;
    cursor: pointer;
    appearance: none;
    background-color: inherit;
}

.starter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    border: 1px solid;
    border-radius: 5px;
    width: 535px;
    height: 200px;
    background-color: rgb(237, 237, 237);
    margin-bottom: 25px;
    cursor: pointer;
}

.starter:hover {
    box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.37);
    cursor: pointer;
    transform: translateY(-2px);

}


.song-details-page-likes-and-times-played {
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: -10px;

}

.song-info-data {
    margin-left: 5px;
    margin-bottom: 20px;
}

.song-info.artist {
    color: #666;
    margin-top: 12px;
    
}
.song-info.genre {
    background-color: #9c8a9b;
    padding: 0px 0px 1px 2px;
    border-radius: 3px;
    font-size: 10px;
    width: fit-content;
    margin-top: 20px;
}

.song-info-edit {
    margin-left: 5px;
}


.individual-comment-in-the-list-song-details-page {
    border: 1px solid black;
    min-width: 515px;
    min-height: 80px;
    padding: 10px;
    border-radius: 8px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.buttons-span {
    margin-bottom: 0px;
    margin-top: 15px;
    position: absolute;
    left: 515px;
}

.space {
    height: 100px;
}

.comment-logo {
    margin-top: 5px;
    margin-right: 5px;
}

.song-artwork {
    height: 150px;
    width: 150px;
    margin-left: 350px;
    margin-top: -70px;
    border-radius: 8px;
}