.plForm {
  display: flex;
  flex-direction: column;
  margin: 25px;
  align-items: center;
  width: 300px;
}

.plForm>h2 {
  margin-top: 0;
}

.plTitle {
  height: 30px;
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
}

.createPlBtn {
  margin-top: 20px;
  color: white;
  height: 36px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  background-color: #ff5500;
  width: 80%;
}

.plTitle-Error {
  color: red;
  margin: 0;
}
