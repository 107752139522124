.add-to-playlist-modal-div {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 400px;
}

.add-to-playlist-modal-div h2,
h3 {
  margin: 0;
}

.add-to-playlist-modal-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 100%;
}

.add-to-playlist-modal-form-select {
  height: 30px;
  width: 100%;
}

.add-to-playlist-modal-form button {
  color: white;
  height: 30px;
  width: 100px;
  border: 1px solid #666;
  border-radius: 4px;
  font-size: 14px;
  background-color: #ff5500;
  width: 120px;
}
