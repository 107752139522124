.login-modal-div {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 180px;
  width: 400px;
  justify-content: space-evenly;
}

.login-label-input-div {
  display: flex;
  align-items: center;
  width: 100%;
}

.login-label {
  display: flex;
  width: 32%;
}

.login-input-text {
  margin-left: 10px;
  height: 30px;
  width: 100%;
}

.login-btn {
  color: white;
  height: 36px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  background-color: #ff5500;
  width: 100%;
}

.demo-user-btn {
  color: #ff5500;
  height: 36px;
  border: 1px solid #ff5500;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
}
