.song-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #f2f2f2;
  margin: 5px;
  width: 150px;
  height: 150px;
  background-color: rgb(237, 237, 237);

}

.song-box:hover {
  box-shadow: 0px 1px 2px 0px;
  cursor: pointer;
  transform: translateY(-2px);
}

.song-box-header {
  display: flex;
  height: 60px;
  width: inherit;
  align-items: center;
}

.song-box-image {
  background-color: rgb(85, 54, 109);
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.song-box-info {
  max-width: 90px;
}
.song-box-info:hover {
  text-decoration: underline;
}
.song-box-info h3 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 1em;
}
.song-box-info p {
  margin: 0;
  color: #666;
}

.genre-div {
  margin-left: 0;
}
.genre-tag {
  display: inline-block;
  background-color: #9c8a9b;
  padding: 2px 5px;
  border-radius: 3px;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 10px;
  margin-top: 5px;
}

.song-stats {
  display: flex;
  width: inherit;
  justify-content: center;
}

.song-stats span {
  color: #333;
}

.song-box-play-div {
  display: flex;
  width: inherit;
  justify-content: space-around;
}
.song-box-play-btn {
  display: none;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  z-index: 1;
}

.song-box:hover .song-box-play-btn {
  display: inline;
}

.song-box-actions {
  display: grid;
  grid: 25px / repeat(auto-fill, 70px);
  grid-gap: 5px;
}

.song-box-action-btn {
  background-color: white;
  color: black;
  border: 1px solid black;
  border-radius: 4px;
  height: 25px;
}

.song-box-liked {
  color: red;
  border: 1px solid red;
}

.song-box-no-user-like {
  display: grid;
  grid:100%;
}

.song-box-add-to-playlist-img {
  height: 13px;
  width: auto;
}

