.plHr2 {
  margin-right: 20px;
}

.plH {
  padding-left: 20px;
}

.plSongDiv {
  padding-left: 20px;
  font-size: 20px;
  font-weight: bolder;
}

.songBy {
  font-size: 15px;
  font-weight: 500;
}
